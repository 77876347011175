//
//キレイ処管理画面
//

//ルートのパス
let controlPath = "/ctrl";
let subDescription = " ｜ ドクターリセラパーフェクトガイド　by伏見嘉洋";
export default {
    routes: [
        {
            path: controlPath + '/',
            name: 'KireiCtrlHome',
            component: () => import('@/views/Ctrl/Kirei/Top.vue'),
            meta: {
                title: 'キレイ処管理画面トップページ' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/login',
            name: 'KireiCtrlLogin',
            component: () => import('@/views/Ctrl/Kirei/Login.vue'),
            meta: {
                title: 'キレイ処管理画面ログインページ' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/logout',
            name: 'KireiCtrlLogout',
            component: () => import('@/views/Ctrl/Kirei/Logout.vue'),
            meta: {
                title: 'キレイ処管理画面ログインアウトページ' + subDescription,
                description: 'ログアウト処理を行います',
            },
        },
        {
            path: controlPath + '/oshirase',
            name: 'KireiCtrlOshirase',
            component: () => import('@/views/Ctrl/Kirei/Oshirase/List.vue'),
            meta: {
                title: 'キレイ処管理画面お知らせ一覧' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/oshirase/edit',
            name: 'KireiCtrlOshiraseEdit',
            component: () =>
                import('@/views/Ctrl/Kirei/Oshirase/EditOshirase.vue'),
            meta: {
                title: 'キレイ処管理画面お知らせ作成・編集' + subDescription,
                description: '',
            },
        },

        //キレイドコロパスワード変更
        {
            path: controlPath + '/passchange',
            name: 'KireiCtrlPassChange',
            component: () =>
                import('@/views/Ctrl/Kirei/PasswordChange.vue'),
            meta: {
                title: 'キレイ処管理画面 ログインパスワード変更' + subDescription,
                description: '',
            },
        },

        //
        //注文管理
        //
        {
            path: controlPath + '/order/list',
            name: 'KireiCtrlOrderList',
            component: () =>
                import('@/views/Ctrl/Order/OrderList.vue'),
            meta: {
                title: '受注データ一覧｜キレイ処管理画面' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/order/detail/:orderId',
            name: 'KireiCtrlOrderDetail',
            component: () =>
                import('@/views/Ctrl/Order/OrderDetails.vue'),
            meta: {
                title: '注文詳細｜キレイ処管理画面' + subDescription,
                description: '',
            },
        },


        //
        //商品管理
        //
        {
            path: controlPath + '/product',
            name: 'Product',
            component: () => import('@/views/Ctrl/products/Product.vue'),
            meta: {
                title: '商品一覧' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/product/edit/',
            // path: controlPath + '/product/edit/',
            name: 'productEdit',
            component: () => import('@/views/Ctrl/products/ProductEdit.vue'),
            meta: {
                title: '商品詳細' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/product/order/',
            name: 'productOrder',
            component: () => import('@/views/Ctrl/products/ProductOrder.vue'),
            meta: {
                title: '商品の並び替え' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/product/confirm/',
            name: 'ProductConfirm',
            component: () => import('@/views/Ctrl/products/ProductConfirm.vue'),
            meta: {
                title: '商品詳細' + subDescription,
                description: '',
            },
        },
        //ポイントキャンペーン設定
        {
            path: controlPath + '/product/campaign/:productId',
            name: 'ProductCampaign',
            component: () => import('@/views/Ctrl/products/Campaign.vue'),
            meta: {
                title: '商品ポイントキャンペーン設定' + subDescription,
                description: '',
            },
        },
        //割引キャンペーン設定
        {
            path: controlPath + '/product/waribiki/:productId',
            name: 'ProductWaribiki',
            component: () => import('@/views/Ctrl/products/Waribiki.vue'),
            meta: {
                title: '商品割引キャンペーン設定' + subDescription,
                description: '',
            },
        },
        //
        //キレイ処管理内
        //ユーザー管理
        //
        {
            path: controlPath + '/user',
            name: 'UserList',
            component: () => import('@/views/Ctrl/User/UserList.vue'),
            meta: {
                title: 'ユーザー一覧' + subDescription,
                description: '' + subDescription,
            },
        },
        {
            path: controlPath + '/user/edit/:user_id',
            name: 'UserEdit',
            component: () => import('@/views/Ctrl/User/UserEdit.vue'),
            meta: {
                title: 'ユーザー編集' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/user/ads/:userId',
            name: 'UserAds',
            component: () => import('@/views/Ctrl/User/Ads.vue'),
            meta: {
                title: 'ユーザーADS情報編集' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/user/ads2/:userId',
            name: 'UserAds2',
            component: () => import('@/views/Ctrl/User/Ads2.vue'),
            meta: {
                title: 'ユーザーADS情報編集' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/user/ads2/idou_preview/:userId',
            name: 'IdoutodokePreview',
            component: () => import('@/views/Ctrl/User/IdoutodokePreview.vue'),
            meta: {
                title: 'ユーザーADS移動届プレビュー' + subDescription,
                description: 'ユーザーADS移動届プレビュー',
            },
        },
        {
            path: controlPath + '/user/confirm/',
            name: 'UserEditConfirm',
            component: () => import('@/views/Ctrl/User/UserEditConfirm.vue'),
            meta: {
                title: 'ユーザー編集確認' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/user/passchange/:userId/:userName',
            name: 'UserPasswordChange',
            component: () => import('@/views/Ctrl/User/PasswordChange.vue'),
            meta: {
                title: 'ユーザーパスワード変更' + subDescription,
                description: '',
            },
        },
        {
            path: controlPath + '/user/point/:userId',
            name: 'UserPoint',
            component: () => import('@/views/Ctrl/User/Point.vue'),
            meta: {
                title: 'ユーザーポイント管理' + subDescription,
                description: '',
            },
        },
        //
        //キャンペーン設定
        //
        {
            path: controlPath + '/campaign',
            name: 'KireiCtrlCampaign',
            component: () => import('@/views/Ctrl/Campaign/Index.vue'),
            meta: {
                title: '全体ポイントキャンペーン設定',
                description: '個別ではない全体のキャンペーンの管理',
            },
        },
        //
        //全体割引設定
        //
        {
            path: controlPath + '/waribiki',
            name: 'KireiCtrlWaribiki',
            component: () => import('@/views/Ctrl/Waribiki/Index.vue'),
            meta: {
                title: '全体割引キャンペーン設定',
                description: '個別ではない全体の割引の管理',
            },
        },

       
    ]
};