//
//三協画面
//

//当日の注文ダウンロード画面のみのはず
let subDescription = " ｜ ドクターリセラパーフェクトガイド　by伏見嘉洋";
export default {
    routes: [
        {
            path: '/sankyo',
            name: 'sankyo_downloads',
            component: () => import('@/views/sankyo/sankyo_downloads.vue'),
            meta: {
                title: '三協様発注書ダウンロード画面' + subDescription,
                description: '発注書ダウンロード画面です',
            },
        },
    ]
};