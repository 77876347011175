import {
    createRouter,
    createWebHistory
} from 'vue-router'
import {
    useStore
} from "@/store/index.js";
import isMobile from "ismobilejs";

//タイトル変更とデスクリプション変更を読み込む
import {
    setTitle,
    setDescription,
    // setViewport
} from "./set_title_description.js";


//=====================
//外部ルート定義読み込み
//=====================
import RouteNormal from "./route.js";
import RouteCtrl from "./route_ctrl.js";
import RouteRecella from "./route_recella.js";
import RouteMyPage from "./route_mypage.js";
import RouteSankyo from "./route_sankyo.js";

//各ルートをマージ
const routes = [
    ...RouteNormal.routes,
    ...RouteCtrl.routes,
    ...RouteRecella.routes,
    ...RouteMyPage.routes,
    ...RouteSankyo.routes,
];



// const router = createRouter({
//     history: createWebHistory(process.env.BASE_URL),
//     routes,
// })




const router = createRouter({
    // history: createWebHistory("/ctrl/"),
    history: createWebHistory(),
    // history: createWebHistory(import.meta.env.BASE_URL),
    // history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                top: 0
            }
        }
    }
});


router.beforeEach((to, from, next) => {
    const Store = useStore();

    console.log("今のURLは、" + window.location.href);
    console.log("今のドメインは、" + window.location.host);

    var host = "https://drr-guide.com/storage";
    var api = "https://drr-guide.com";
    if (window.location.host != "drr-guide.com") {
        // server_url = "http://drr-guide.localhost";
        host = "http://localhost/storage";
        api = "http://localhost";
        //ローカルである事をセット
        Store.setIsLocal(true);
        console.log("ローカルフラグセット");
        console.log("ローカルフラグ:" + Store.isLocal);
    }
    //Storeにセット
    Store.setHost(host);
    Store.setApi(api);
    console.log(
        "Store.host:" +
        Store.host +
        "に切り替わりました。"
    );
    console.log(
        "Store.api:" +
        Store.api +
        "に切り替わりました。"
    );

    // 全体キャンペーン情報を格納
    Store.setWholeCampaignData();

    //
    //モバイル判定
    //
    //モバイルならviewportをスマホにセットし直す
    const userAgent = navigator.userAgent;
    console.log(userAgent);
    console.log(isMobile(userAgent));
    var result = isMobile(userAgent);
    // "width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no";
    let viewportContent = "width=1200";
    if (result.phone) {
        //SmartPhone
        viewportContent =
            "width=375,user-scalable=no,viewport-fit=cover";
        //Storeへコミット
        Store.setIsMobile(true);
    } else {
        Store.setIsMobile(false);
    }
    document
        .querySelector("meta[name='viewport']")
        .setAttribute("content", viewportContent);

    //
    //タイトル・デスクリプションセット
    //
    // setTitle(to.meta.title + " ｜ ドクターリセラパーフェクトガイド　by伏見嘉洋");
    setTitle(to.meta.title);
    setDescription(to.meta.description);


    next();

});

export default router;
