//
//ドクターリセラ管理画面
//

//ルートのパス
let recellaPath = "/recella";
let subDescription = " ｜ ドクターリセラパーフェクトガイド　by伏見嘉洋";
export default {
    routes: [
        {
            path: recellaPath + '/',
            redirect: '/recella/top',
        },
        //
        {
            path: recellaPath + '/top',
            name: 'RecellaCtrlTop',
            component: () => import('@/views/Ctrl/recella/Top.vue'),
            meta: {
                title: 'ドクターリセラ管理画面' + subDescription,
                description: '',
            },
        },
        {
            path: recellaPath + '/login',
            name: 'RecellaCtrlLogin',
            component: () => import('@/views/Ctrl/recella/Login.vue'),
            meta: {
                title: 'ドクターリセラ管理画面ログインページ' + subDescription,
                description: '',
            },
        },
        {
            path: recellaPath + '/logout',
            name: 'RecellaCtrlLogout',
            component: () => import('@/views/Ctrl/recella/Logout.vue'),
            meta: {
                title: 'ドクターリセラ管理画面ログアウト' + subDescription,
                description: '',
            },
        },
        {
            path: recellaPath + '/ads/user',
            name: 'RecellaADSUserList',
            component: () => import('@/views/Ctrl/recella/AdsUser.vue'),
            meta: {
                title: 'ドクターリセラADS移動届' + subDescription,
                description: '',
            },
        },
        {
            path: recellaPath + '/ads/user/detail/:userId',
            name: 'RecellaADSUserDetail',
            component: () => import('@/views/Ctrl/recella/AdsUserDetail.vue'),
            meta: {
                title: 'ドクターリセラADS移動届詳細' + subDescription,
                description: '',
            },
        },
        {
            path: recellaPath + '/passChange',
            name: 'RecellaADSPasswordChange',
            component: () => import('@/views/Ctrl/recella/PasswordChange.vue'),
            meta: {
                title: 'ドクターリセラ ログイン用パスワード変更' + subDescription,
                description: '',
            },
        },
    ]
};