
//
//マイページ
//


//ルートのパス
let myPagePath = "/my";
let subDescription = " ｜ ドクターリセラパーフェクトガイド　by伏見嘉洋";
export default {
    routes: [
        {
            path: myPagePath + '/top',
            name: 'MyTop',
            component: () => import('@/views/MyPage/MyTop.vue'),
            meta: {
                title: 'マイページ｜トップ' + subDescription,
                description: 'マイページのトップメニューです。',
            },
        },
        //ログイン・ログアウトは「/my/」を付けないので注意
        {
            path: '/login',
            name: 'MyPageLogin',
            component: () => import('@/views/MyPage/Login.vue'),
            meta: {
                title: 'マイページ｜ログイン' + subDescription,
                description: 'ログインページです。',
            },
        },
        //ログイン・ログアウトは「/my/」を付けないので注意
        {
            path: '/logout',
            name: 'MyPageLogout',
            component: () => import('@/views/MyPage/Logout.vue'),
            meta: {
                title: 'マイページ｜ログアウト' + subDescription,
                description: 'ログアウトページです。',
            },
        },
        {
            path: myPagePath + '/orderList',
            name: 'MyPageOrderList',
            component: () => import('@/views/MyPage/OrderList.vue'),
            meta: {
                title: 'マイページ｜注文履歴' + subDescription,
                description: '注文履歴ページです。',
            },
        },
        {
            path: myPagePath + '/orderDetails/:order_id',
            name: 'MyPageOrderDetails',
            component: () => import('@/views/MyPage/OrderDetails.vue'),
            meta: {
                title: 'マイページ｜注文詳細' + subDescription,
                description: '注文内容詳細ページです。',
            },
        },
        {
            path: myPagePath + '/editprof',
            name: 'MyPageDataEdit',
            component: () => import('@/views/MyPage/MyDataEdit.vue'),
            meta: {
                title: 'マイページ｜会員情報の確認・編集' + subDescription,
                description: '会員情報の確認・編集ページです。',
            },
        },
        {
            path: myPagePath + '/editprof/confirm',
            name: 'MyPageDataEditConfirm',
            component: () => import('@/views/MyPage/MyDataEditConfirm.vue'),
            meta: {
                title: 'マイページ｜会員情報の入力確認' + subDescription,
                description: '会員情報の入力確認ページです。',
            },
        },
        {
            path: myPagePath + '/destination',
            name: 'MyPageDestination',
            component: () => import('@/views/MyPage/Destination.vue'),
            meta: {
                title: 'マイページ｜送付先情報一覧' + subDescription,
                description: '送付先情報の一覧ページです。',
            },
        },
        {
            path: myPagePath + '/destination/edit/:dno',
            name: 'MyPageDestinationEdit',
            component: () => import('@/views/MyPage/DestinationEdit.vue'),
            meta: {
                title: 'マイページ｜送付先情報編集' + subDescription,
                description: '送付先情報の編集ページです。',
            },
        },
        {
            path: myPagePath + '/destination/edit/confirm',
            name: 'MyPageDestinationEditConfirm',
            component: () => import('@/views/MyPage/DestinationEditConfirm.vue'),
            meta: {
                title: 'マイページ｜送付先情報編集内容確認' + subDescription,
                description: '送付先情報編集の内容確認ページです。',
            },
        },
        {
            path: myPagePath + '/password-change',
            name: 'MyPagePasswordChange',
            component: () => import('@/views/MyPage/PasswordChange.vue'),
            meta: {
                title: 'マイページ｜パスワード変更' + subDescription,
                description: 'パスワード変更ページです。',
            },
        },
        {
            path: myPagePath + '/movie-list',
            name: 'MyPageMovieList',
            component: () => import('@/views/MyPage/MovieList.vue'),
            meta: {
                title: 'マイページ｜動画視聴' + subDescription,
                description: '施術動画等がご覧いただけます',
            },
        },
        {
            path: myPagePath + '/document',
            name: 'MyVerifyDocument',
            component: () => import('@/views/MyPage/KakuninShorui.vue'),
            meta: {
                title: 'マイページ｜本人確認書類' + subDescription,
                description: '本人確認書類の確認、アップロード、削除が行なえます',
            },
        },
    ]
};