<template>
    <div class="footerFixed">
        <router-view></router-view>
    </div>
</template>

<style lang="scss">
:root {
    /* color */
    --red: #ff6568;
    --orange: #ee9200;
}

.footerFixed {
    min-height: 100vh;
    /* ←コンテンツの高さの最小値＝ブラウザの高さに指定 */
    position: relative;
    /* ←相対位置 */
    padding-bottom: 60px;
    /* ←フッターの高さを指定 */
    box-sizing: border-box;
}
</style>
