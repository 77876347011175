import "./bootstrap";

import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router/index.js";
//
import "@/scss/main.scss";
//
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";

//Element-Plusのインポート＆日本語ファイル読み込み
import ElementPlus from "element-plus";
// import 'element-plus/lib/theme-chalk/index.css' //1.0.*までの// if you're using CDN, please remove this line.
// if you're using CDN, please remove this line.
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import "element-plus/dist/index.css"; //1.1.0以降
import locale from "element-plus/lib/locale/lang/ja";
import "dayjs/locale/ja";


//Pinia
const pinia = createPinia();
pinia.use(piniaPersist);

const app = createApp(App);

//Element-UIのアイコンを登録していく
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

app.use(ElementPlus, { locale }).use(pinia).use(router).mount("#app");
